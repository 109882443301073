import { Avatar } from "@mui/material";
import { useStorageFile } from "@stai/common";
import { useProject } from "../../Project/hooks/useProject";

const AVATAR_SIZE = 96;

export const ProjectPicture = ({
  projectId,
  size = AVATAR_SIZE,
}: {
  projectId: string;
  size?: number | string;
}) => {
  const [project] = useProject(projectId);
  const imageFile = useStorageFile(project?.profilePictureFilePath);

  const aspectRatio = 1; // Since avatar is square, use 1:1 aspect ratio

  const wrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as 'relative',
    width: typeof size === "string" ? size : `${size}px`,
    paddingBottom: typeof size === "string" ? size : `${size}px`,
    height: 0,
  };

  const avatarStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    bgcolor: 'primary.main',
  };

  return (
    <div style={wrapperStyle}>
      <Avatar
        src={imageFile.url}
        sx={avatarStyle}
      />
    </div>
  );
};