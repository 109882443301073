import { PaletteOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypeBackground {
    gradient: string;
  }
}

export const MAIN_PALETTE = {
  mode: "dark",
  background: {
    default: "#141414",
    paper: "#000000",
    gradient: "linear-gradient(180deg, #49E374 0%, #00AA41 100%)",
  },
  common: {
    black: "#141414",
    white: "#FFFFFF",
  },
  divider: "rgba(255, 255, 255, 0.1)",
  text: {
    primary: "#FFFFFF",
    secondary: "#8C8C8C",
  },
  primary: {
    main: "#49E374",
  },
  secondary: {
    main: "#FaD81C",
  },
  info: {
    main: "#4999E2",
  },
  warning: {
    main: "#FF5C00",
  },
  error: {
    main: "#FF5252",
  },
  success: {
    main: "#38D075",
  },
  grey: {
    "100": "#8C8C8C",
    "400": "#3D3D3D",
    "500": "#333333",
    "700": "#212121",
  },
} satisfies PaletteOptions;


export const COLORS = {
  whatsapp: "#25D366",
  telegram: "#229ED9",
}