import WebIcon from "@mui/icons-material/Web";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingView } from "../../Common/views/LoadingView";
import { useProject } from "../../Project/hooks/useProject";
import { generateStartMessage, generateTelegramUrl, generateWebUrl, generateWhatsappUrl } from "@stai/types";
import { ProjectPicture } from "../../Common/views/ProjectPicture";
import { COLORS } from "../../Main/consts/MAIN_PALETTE";

export const MessengersPage: FC = () => {
  const { projectId, stayId } = useParams<{
    projectId: string;
    stayId: string;
  }>();
  const [open, setOpen] = React.useState(true);
  const [project, loading, error] = useProject(projectId);
  const { t } = useTranslation();

  console.log("projectId", projectId);
  if (loading) return <LoadingView />;
  if (!projectId) return <ErrorPage error="Project id is required" />;
  // if (!stayId) return <ErrorPage error="Stay id is required" />;
  if (error || !project) return <ErrorPage error="Project not found" />;

  const handleClose = () => {
    setOpen(false);
  };

  const browserLanguage = navigator.language ?? "en-GB";

  const startMessage = generateStartMessage({
    stayId,
    projectId,
    language: browserLanguage,
  });
  
  const whatsappUrl = generateWhatsappUrl(process.env.NEXT_PUBLIC_WHATSAPP_SHARED_PHONE_NUMBER!, startMessage)
  const telegramUrl = generateTelegramUrl(project.telegramBotName, startMessage)
  const webUrl = generateWebUrl(ENVIRONMENT.messengerDomain, projectId, stayId)

  return (
    <Stack
      justifyContent="center"
      flex={1}
      spacing={4}
      sx={{ padding: "20px" }}
    >
      <Box>
        <Typography variant="h1" textAlign="center">
          {t("welcome")}
          <br />
          {project?.name}
        </Typography>
      </Box>
      <Stack
        justifyContent="center"
        alignItems="center"
        flex={1}
        spacing={4}
        sx={{ padding: "20px" }}
      >
        <ProjectPicture projectId={project.id} size={"50%"}/>

        <Typography variant="h2" textAlign="center">
          {t("guest_choose_messenger")}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          flexWrap="wrap"
        >
          {whatsappUrl && (
            <Card
              sx={{
                p: 1,
                backgroundColor: "white",
                color: "black",
                flex: "1 1 calc(33.33% - 16px)",
                borderRadius: "20px",
                maxWidth: "calc(33.33% - 16px)",
                boxSizing: "border-box",
              }}
            >
              <CardActionArea
                component={Link}
                to={whatsappUrl}
                sx={{ borderRadius: "20px" }}
              >
                <CardContent>
                  <Stack alignItems="center" spacing={1}>
                    <WhatsAppIcon sx={{ fontSize: { xs: 30, sm: 40, md: 50 }, color: COLORS }} />
                    <Typography variant="body1" sx={{ fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' }}}>
                      WhatsApp
                    </Typography>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
          {telegramUrl && (
            <Card
              sx={{
                p: 1,
                backgroundColor: "white",
                color: "black",
                flex: "1 1 calc(33.33% - 16px)",
                borderRadius: "20px",
                maxWidth: "calc(33.33% - 16px)",
                boxSizing: "border-box",
              }}
            >
              <CardActionArea component={Link} to={telegramUrl}>
                <CardContent>
                  <Stack alignItems="center" spacing={1}>
                    <TelegramIcon sx={{ fontSize: { xs: 30, sm: 40, md: 50, color: COLORS.telegram } }} />
                    <Typography variant="body1" sx={{ fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' }}}>
                      Telegram
                    </Typography>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
          <Card
            sx={{
              p: 1,
              backgroundColor: "white",
              color: "black",
              flex: "1 1 calc(33.33% - 16px)",
              borderRadius: "20px",
              maxWidth: "calc(33.33% - 16px)",
              boxSizing: "border-box",
            }}
          >
            <CardActionArea component={Link} to={webUrl}>
              <CardContent>
                <Stack alignItems="center" spacing={1}>
                  <WebIcon color="info" sx={{ fontSize: { xs: 30, sm: 40, md: 50 } }} />
                  <Typography variant="body1" sx={{ fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' }}}>
                    Web
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      </Stack>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "30px" }}>
          {t("gdpr_consent_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("gdpr_consent_message")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="success"
            size="large"
            sx={{ width: "100%" }}
          >
            {t("agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};