import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import QRCode from "react-qr-code";
import { Link, useParams } from "react-router-dom";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { useProject } from "../../Project/hooks/useProject";
import { useStay } from "../hooks/useStay";
import { ProjectPicture } from "../../Common/views/ProjectPicture";
export const StayQRCode: FC = () => {
  const { projectId, stayId } = useParams<{
    projectId: string;
    stayId: string;
  }>();

  if (!projectId) return <ErrorPage error="Project id is required" />;

  const [project, isLoadingProject] = useProject(projectId);
  const [stay, isLoadingStay] = useStay(projectId, stayId);

  const { t } = useTranslation();

  if (isLoadingStay || isLoadingProject) return <LoadingPage />;
  if (!project) return <ErrorPage error="Project not found" />;
  if (stayId && !stay) return <ErrorPage error="Stay not found" />;
  const host = window.location.host;
  const url = `http://${host}/projects/${projectId}/stays/${stayId}/messenger`;

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <Stack justifyContent="center" flex={1} spacing={4} alignItems="center">
      <Typography variant="h1" textAlign="center">
        {t("welcome")}
        <br />
        {stay?.name}
      </Typography>
      <ProjectPicture projectId={project.id} size={"50%"}/>
      <Typography variant="h2" textAlign="center">
        {t("please_scan_qr_code")}
      </Typography>

      <QRCode
        value={url + `?name=${stay?.name}`}
        onClick={copyUrlToClipboard}
      />

      <Button
        variant="contained"
        color="success"
        size="medium"
        component={Link}
        to={`/projects/${projectId}`}
        sx={{ width: "70%" }}
      >
        {t("back")}
      </Button>
    </Stack>
  );
};
